<!-- =========================================================================================
    File Name: SequencingNavbar.vue
    Description: Sequencing module navbar
========================================================================================== -->

<template>
        <vs-navbar  color="white" type="flat" class="p-2">
          <div slot="title">
        <vs-navbar-title>
          
        </vs-navbar-title>
      </div>
      <vs-navbar-item index="0" class="mr-1">
        <vs-button type="border" @click="$router.go(-1)" color="primary">Back</vs-button>
      </vs-navbar-item>
      <vs-navbar-item index="1">
        <vs-button type="filled" color="primary" @click="$emit('saveSequence')">Save</vs-button>
      </vs-navbar-item>
               
          <!-- <div class="relative flex mr-4">
            <div class="w-full">
      <vs-row vs-type="flex" vs-justify="space-between">
        <div style="padding: 0 1rem;" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-3/12 xl:w-3/12">
          <vs-button class="w-5/12 mr-6" type="border" @click="$router.go(-1)" color="primary">Back</vs-button>
          <vs-button @click="updateSpace()" class="w-6/12" type="border" color="primary">Save</vs-button>
        </div>
        <div style="padding: 0 1rem;" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-5/12 xl:w-5/12"></div>
      </vs-row>
    </div>
              <div class="h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0" :class="'bg-' + getStatusColor(false)"></div>
          </div>
          <h6>TOTOT</h6>
          <vs-spacer></vs-spacer> -->
          </vs-navbar>
</template>

<script>
export default {
  props: {
    sequenceId: {
      type: String,
      required: false
    },
    isDirty: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    getStatusColor () {
      return (isActiveUser) => {
        const userStatus = this.getUserStatus(isActiveUser)

        if (userStatus === 'online') {
          return 'success'
        } else if (userStatus === 'do not disturb') {
          return 'danger'
        } else if (userStatus === 'away') {
          return 'warning'
        } else {
          return 'grey'
        }
      }
    }
  },
  methods: {
    getUserStatus (isActiveUser) {
      return isActiveUser ? this.$store.state.AppActiveUser.status : this.userDetails.status
    }
  }
}

</script>

