const actionSetMaterialsVariantSchema = [
  {
    type: 'select',
    label: 'Variant Index:',
    name: 'variant_index',
    options: {},
    events: ['input'],
  },
]

const actionSetMaterialsVariantValue = {
  type: 'action_set_materials_variant',
}

export { actionSetMaterialsVariantSchema, actionSetMaterialsVariantValue }
