
const actionRaiseEventSchema = [
  {
    type: 'text',
    name: 'event_message',
    label: 'Message',
    events: ['input'],
  },
]

const actionRaiseEventValue = {
  event_message: '',
}

export { actionRaiseEventSchema, actionRaiseEventValue }
