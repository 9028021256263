const actionChangeSpaceSchema = [
  {
    type: 'select',
    label: 'Space:',
    name: 'space_pid',
    options: {},
    events: ['input'],
  },
  {
    type: 'checkbox',
    name: 'keep_origin',
    label: 'Retain current space origin position and orientation',
    events: ['input'],
  },
]

const actionChangeSpaceValue = {
  type: 'action_change_space',
  keep_origin: false,
}

export { actionChangeSpaceSchema, actionChangeSpaceValue }
