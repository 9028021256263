const actionHideSchema = [
  {
    type: 'select',
    label: 'Exit Animation:',
    name: 'exit_type',
    options: {
      none: 'None',
      to_origin: 'To Origin',
      to_left: 'To Left',
      to_right: 'To Right',
      to_above: 'To Above',
      to_below: 'To Below',
      to_rear: 'To Rear',
      to_front: 'To Front',
      scale_up: 'Scale Up',
      scale_down: 'Scale Down',
    },
    validation: 'required',
    events: ['input'],
  },
  {
    type: 'number',
    name: 'duration',
    label: 'Duration (seconds):',
    vfeNumber: true,
    events: ['input'],
    min: 0,
  },
  {
    type: 'select',
    label: 'Easing:',
    name: 'easing',
    options: {
      linear: 'Linear',
      sinein: 'Smooth Start',
      sineout: 'Smooth End',
      sineinout: 'Smooth Start & End',
      elasticin: 'Elastic Start',
      elasticout: 'Elastic End',
      elasticinout: 'Elastic Start & End',
      backin: 'Overshoot on Start',
      backout: 'Overshoot on End',
      backinout: 'Overshoot on Start & End',
      // linear: 'Linear',
      // sinein: 'SineIn',
      // sineout: 'SineOut',
      // sineinout: 'SineInOut',
      // quadin: 'QuadIn',
      // quadout: 'QuadOut',
      // quadinout: 'QuadInOut',
      // cubicin: 'CubicIn',
      // cubicout: 'CubicOut',
      // cubicinout: 'CubicInOut',
      // quartin: 'QuartIn',
      // quartout: 'QuartOut',
      // quartinout: 'QuartInOut',
      // quintin: 'QuintIn',
      // quintout: 'QuintOut',
      // quintinout: 'QuintInOut',
      // expoin: 'ExpoIn',
      // expoout: 'ExpoOut',
      // expoinout: 'ExpoInOut',
      // circin: 'CircIn',
      // circout: 'CircOut',
      // circinout: 'CircInOut',
      // backin: 'BackIn',
      // backout: 'BackOut',
      // backinout: 'BackInOut',
      // elasticin: 'ElasticIn',
      // elasticout: 'ElasticOut',
      // elasticinout: 'ElasticInOut',
      // bouncein: 'BounceIn',
      // bounceout: 'BounceOut',
      // bounceinout: 'BounceInOut',
    },
    validation: 'required',
    events: ['input'],
  },
  // {
  //   type: 'number',
  //   name: 'distance',
  //   label: 'Distance (meters):',
  //   vfeNumber: true,
  //   events: ['input'],
  //   min: 0,
  // },
  // {
  //   type: 'number',
  //   name: 'scale_factor',
  //   label: 'Scale multiplier (number):',
  //   vfeNumber: true,
  //   events: ['input'],
  //   min: 0,
  // },
]

const actionHideValue = {
  exit_type: 'scale_down',
  distance: 5,
  duration: 3,
  scale_factor: 10,
  easing: 'backout',
}

export { actionHideSchema, actionHideValue }
