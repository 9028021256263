<template>
  <div>
    <div>
      <sequencing-navbar @saveSequence="saveSequence" :isDirty="true"></sequencing-navbar>
    </div>
    <sequencing
      ref="sequencingComponent"
      v-if="space"
      v-bind:space="space"
      v-bind:nodes="nodes"
      v-bind:anchors_spaces="anchors_spaces"
      v-bind:anchors="anchors"
      v-bind:hobjects="hobjects"
      v-bind:nodePidHobjectNameDictionnary="nodePidHobjectNameDictionnary"
      v-bind:spacePidNameDictionnary="spacePidNameDictionnary"
    ></sequencing>
  </div>
</template>

<script>
import Sequencing from '@/views/hoverlay/pages/sequencing/Sequencing.vue'
import SequencingNavbar from '@/views/hoverlay/pages/sequencing/SequencingNavbar.vue'

export default {
  data() {
    return {
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
    }
  },
  components: {
    Sequencing,
    SequencingNavbar
  },
  watch: {},
  computed: {
    space_pid: function() {
      return this.$route.params.pid
    },
    space() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.spaces.find(space => space.pid == this.space_pid)
      } else return null
    },
    spaces() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.spaces
      } else return null
    },
    nodes() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.nodes.filter(node => node.space_pid == this.space_pid)
      } else return null
    },
    anchors_spaces() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors_spaces
      } else return null
    },
    anchors() {
      var anchoractions = []
      this.nodes.forEach(node => {
        var tmpAnchor = this.$store.state.hoverlay.anchors.find(anchor => anchor.pid == node.anchor_pid)
        if (tmpAnchor && anchoractions.find(anchor => anchor.pid == tmpAnchor.pid) == undefined)
          anchoractions.push(tmpAnchor)
      })
      return anchoractions
    },
    hobjects() {
      var h = []
      this.nodes.forEach(node => {
        var tmp = this.$store.state.hoverlay.hobjects.find(hobject => hobject.pid == node.hobject_pid)
        if (tmp) h.push(tmp)
      })
      return h
    },
    nodePidHobjectNameDictionnary() {
      var d = {}
      this.nodes.forEach(node => {
        var tmp = this.$store.state.hoverlay.hobjects.find(hobject => hobject.pid == node.hobject_pid)
        if (tmp) d[node.pid] = tmp.name
      })
      return d
    },
    spacePidNameDictionnary() {
      var d = {}
      this.spaces.forEach(space => {
        d[space.pid] = space.name
      })
      return d
    },
    models() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.models
      } else return null
    },
  },
  mounted() {},
  created() {
    this.getSpaceContent()
  },
  methods: {
    async getSpaceContent() {
      this.$vs.loading()
      var params = {
        layer_name: this.layer_name,
        pid: this.$route.params.pid,
      }
      await this.$store.dispatch('hoverlay/getSpaceContent', params)
      this.$vs.loading.close()
    },
    saveSequence() {
      this.$refs.sequencingComponent.updateSpace();
    }
  },
}
</script>

<style lang="scss"></style>
