const actionStartFollowingSchema = [
  {
    type: 'select',
    label: 'Tag Along Type:',
    name: 'tag_along_type',
    options: {
      front: 'Front',
      side: 'Side',
    },
    events: ['input'],
  },
  {
    class: 'flex',
    type: 'checkbox',
    name: 'smoothing',
    label: 'Smooth',
    events: ['input'],
  },
]

const actionStartFollowingValue = {
  type: 'action_show',
  smoothing: true,
}

export { actionStartFollowingSchema, actionStartFollowingValue }
