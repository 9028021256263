const actionAnimateSchema = [
  {
    type: 'select',
    label: 'Animation Name:',
    name: 'animation_clip_name',
    options: {},
    events: ['input'],
  },
]

const actionAnimateValue = {
  type: 'action_animate',
  wait_until_finished: false,
}

export { actionAnimateSchema, actionAnimateValue }
