const actionOpenUrlSchema = [
  {
    type: 'url',
    name: 'url',
    label: 'Link',
    events: ['input'],
    validation: 'url',
  },
  {
    type: 'range',
    name: 'height_percentage',
    label: 'Browser Height Ratio',
    validation: 'required|max:1|min:0',
    min: '0',
    max: '1',
    step: 0.1,
    events: ['input'],
    showValue: true,
    help: '1 for fullscreen, 0.5 for half-screen',
  },
  {
    type: 'checkbox',
    name: 'is_fullscreen',
    help: 'If checked opens a fullscreen external browser (incl. cookies support)',
    label: 'Use External Browser',
    events: ['input'],
  },
]

const actionOpenUrlValue = {
  type: 'action_open_url',
  height_percentage: 0.5,
  url: 'https://hoverlay.com',
  wait_until_finished: true,
}

export { actionOpenUrlSchema, actionOpenUrlValue }
