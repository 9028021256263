const actionShowInstructionSchema = [
  {
    type: 'text',
    name: 'instructions_text',
    label: 'Instructions Text',
    events: ['input'],
  },
  // {
  //   type: 'text',
  //   name: 'instructions_text_background',
  //   label: 'Intructions Text Background Color',
  //   events: ['input'],
  // },
  {
    type: 'number',
    name: 'duration',
    label: 'Duration: (in seconds)',
    events: ['input'],
  },
]

const actionShowInstructionValue = {
  // instructions_text_background: '#00000000',
  instructions_text: '',
  duration: 5,
}

export { actionShowInstructionSchema, actionShowInstructionValue }
