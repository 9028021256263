const actionSpinSchema = [
  {
    type: 'select',
    label: 'Axis:',
    name: 'axis',
    options: {
      right: 'Right',
      up: 'Up',
      rear: 'Rear',
    },
    events: ['input'],
  },
  {
    type: 'checkbox',
    name: 'use_world_axis',
    label: 'If checked, axis are world space axis. Otherwise, local to object.',
    events: ['input'],
  },
  {
    type: 'select',
    label: 'Direction:',
    name: 'direction',
    options: {
      clockwise: 'Clockwise',
      counterclockwise: 'Counterclockwise',
    },
    events: ['input'],
  },
  {
    type: 'number',
    name: 'cycle_time',
    label: 'Cycle Time: (in seconds)',
    events: ['input'],
    help: 'Defines one 360° cycle duration.',
    min: '0',
    step: 1,
  },
  {
    type: 'number',
    name: 'duration',
    label: 'Duration: (in seconds)',
    events: ['input'],
    help: 'Defines the overwall animation duration. Set to -1 for infinite.',
    min: '-1',
    step: 1,
  },
  {
    type: 'checkbox',
    name: 'rewind_when_done',
    label: 'Rewind when done',
    events: ['input'],
  },
]
const actionSpinValue = {
  type: 'action_spin',
  axis: 'up',
  cycle_time: 3,
  rewind_when_done: true,
  use_world_axis: true,
  duration: 10,
}
export { actionSpinSchema, actionSpinValue }
