const actionTransformBySchema = [
  {
    type: 'number',
    name: 'duration',
    label: 'Duration (seconds):',
    min: '0',
    step: 0.2,
    vfeNumber: true,
    events: ['input'],
  },
  {
    type: 'select',
    label: 'Easing:',
    name: 'easing',
    options: {
      linear: 'Linear',
      sinein: 'Smooth Start',
      sineout: 'Smooth End',
      sineinout: 'Smooth Start & End',
      elasticin: 'Elastic Start',
      elasticout: 'Elastic End',
      elasticinout: 'Elastic Start & End',
      backin: 'Overshoot on Start',
      backout: 'Overshoot on End',
      backinout: 'Overshoot on Start & End',
      // linear: 'Linear',
      // sinein: 'SineIn',
      // sineout: 'SineOut',
      // sineinout: 'SineInOut',
      // quadin: 'QuadIn',
      // quadout: 'QuadOut',
      // quadinout: 'QuadInOut',
      // cubicin: 'CubicIn',
      // cubicout: 'CubicOut',
      // cubicinout: 'CubicInOut',
      // quartin: 'QuartIn',
      // quartout: 'QuartOut',
      // quartinout: 'QuartInOut',
      // quintin: 'QuintIn',
      // quintout: 'QuintOut',
      // quintinout: 'QuintInOut',
      // expoin: 'ExpoIn',
      // expoout: 'ExpoOut',
      // expoinout: 'ExpoInOut',
      // circin: 'CircIn',
      // circout: 'CircOut',
      // circinout: 'CircInOut',
      // backin: 'BackIn',
      // backout: 'BackOut',
      // backinout: 'BackInOut',
      // elasticin: 'ElasticIn',
      // elasticout: 'ElasticOut',
      // elasticinout: 'ElasticInOut',
      // bouncein: 'BounceIn',
      // bounceout: 'BounceOut',
      // bounceinout: 'BounceInOut',
    },
    events: ['input'],
  },
  // position
  {
    type: 'number',
    name: 'move_by_x',
    label: 'Position X',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  {
    type: 'number',
    name: 'move_by_y',
    label: 'Position Y',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  {
    type: 'number',
    name: 'move_by_z',
    label: 'Position Z',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  // euler
  {
    type: 'number',
    name: 'euler_rotate_by_x',
    label: 'Rotation X',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  {
    type: 'number',
    name: 'euler_rotate_by_y',
    label: 'Rotation Y',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  {
    type: 'number',
    name: 'euler_rotate_by_z',
    label: 'Rotation Z',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
  // scale
  {
    type: 'number',
    name: 'scale_by',
    label: 'Scale',
    vfeNumber: true,
    events: ['input'],
    validation: 'required',
    step: 0.5,
  },
]

const actionTransformByValue = {
  type: 'action_transform_by',
  duration: 0,
  easing: 'sineinout',
  move_by_x: 0,
  move_by_y: 0,
  move_by_z: 0,
  euler_rotate_by_x: 0,
  euler_rotate_by_y: 0,
  euler_rotate_by_z: 0,
  scale_by: 1,
}

export { actionTransformBySchema, actionTransformByValue }
