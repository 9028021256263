const actionPlayParticlesSchema = [
  {
    type: 'number',
    name: 'emission_rate_over_time',
    label: 'Emission Rate Over Time:',
    help: 'Defines the rate at which new particles are spawned over time.',
    vfeNumber: true,
    events: ['input'],
    validation: 'optional',
    min: 0,
  },
  {
    type: 'number',
    name: 'emission_rate_over_time_multiplier',
    label: 'Emission Rate Over Time Multiplier:',
    help: 'Adds a multiplier to the current particle spawning rate.',
    vfeNumber: true,
    events: ['input'],
    validation: 'optional',
    min: 0,
  },
  {
    type: 'number',
    name: 'duration',
    label: 'Duration: (in seconds)',
    events: ['input'],
    help: 'Defines the overwall particle system duration. Set to -1 for infinite.',
    min: '-1',
    step: 1,
  },
]

const actionPlayParticlesValue = {
  type: 'action_play_particles',
  emission_rate_over_time: 10,
  emission_rate_over_time_multiplier: 1,
  duration: 5,
  wait_until_finished: false,
}

export { actionPlayParticlesSchema, actionPlayParticlesValue }
